body {
  font-family: 'Noto Sans JP Regular', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Noto Sans JP Medium';
  src: local('NotoSansJP-Medium'),
    url('../../assests/fonts/NotoSansJP-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans JP Light';
  src: local('NotoSansJP-Light'), url('../../assests/fonts/NotoSansJP-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans JP Regular';
  src: local('NotoSansJP-Regular'),
    url('../../assests/fonts/NotoSansJP-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assests/fonts/Inter-Regular.ttf');
}

.with_roboto {
  font-family: 'Roboto', serif;
}

html {
  scroll-behavior: smooth;
}

.circle_progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.down_polygon {
  clip-path: polygon(0 0, 50% 70%, 100% 0);
}
.up_polygon {
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

input,
textarea {
  border-radius: 5px;
  border: 1px solid #ebe4d8;
  background: white;
}

input[type='text'] {
  border-radius: 5px !important;
  border: 1px solid #ebe4d8;
  background: white !important;
  padding-block: 5px;
  padding-left: 13px;
  font-size: 12px;
}

select {
  border-radius: 5px;
  border: 1px solid #ebe4d8;
  padding: 0;
}

input,
textarea {
  width: 100%;
}

.lds-dual-ring {
  display: inline-block;
  width: 34px;
  height: 24px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 24px;
  margin-left: 4px;
  height: 24px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  border-radius: 5px;
  border: 1px solid #ebe4d8;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.smaller-scroll-bar::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e1e3e2;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8fc2d5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7cb2c5;
}

.confirm_popup-cancel_button {
  color: #7a7a7a;
  border: 1px solid #7a7a7a;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.confirm_popup-confirm_button {
  color: #fa646a;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-transform: capitalize;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  visibility: hidden;
  border-radius: 50%;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* checkbox customizes */
input[type='checkbox'] {
  color: transparent;
  border: 1px solid #ebe4d8;
  border-radius: 3px;
}

input[type='checkbox']:hover {
  background-color: white;
  border: 1px solid #ebe4d8;
  border-radius: 3px;
}

input[type='checkbox']:checked {
  background-image: url('../images/icons/check.svg');
  background-color: white;
  border: 1px solid #ebe4d8;
  border-radius: 3px;
}

button:hover {
  background-image: linear-gradient(#00000026 0 0);
}

a:hover {
  background-image: linear-gradient(#00000026 0 0);
}

select:hover {
  cursor: pointer;
}

input[type='radio']:hover,
input[type='checkbox'] {
  cursor: pointer;
}

button:hover {
  background-image: linear-gradient(rgb(0 0 0/15%) 0 0);
}
/* More speed, darker colored */
/* .skeleton {
  content: ' ';
  cursor: progress;
  background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right / 300% 100%;
  animation: loading 3s linear infinite;
  border-radius: 3px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} */

/* More gentle colored */
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 79%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
